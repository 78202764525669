@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

html,
body {
  font-family: 'Inter', sans-serif;
}

@media (min-width: 640px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  html {
    font-size: 18px;
  }
}

@media (min-width: 1024px) {
  html {
    font-size: 20px;
  }
}

@media (min-width: 1280px) {
  html {
    font-size: 22px;
  }
}

.signatureCanvas{
  border:1px solid black;
  width:92%;
  height: 10rem;
}